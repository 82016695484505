import { router } from '@inertiajs/vue3';

var xhr = {
    
    request: null, 

    /**
     * Get http request
     * @param {string} method GET or POST
     * @param {string} route Route defined in web.php
     * @param {object} progress object of progressbar to be hidden in case of error
     */
    get (route, method, progress) {

        this.request = new XMLHttpRequest();
        this.request.open(method, route, true);
        this.request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        this.request.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
        this.request.onerror = function() { 
            console.warn("http request:", getErrorObject() );

            if(progress) {
                progress.value.hide();
            }
        };
    }, 

    /**
     * Stringify object and send to server 
     * @param {*} data 
     */
    send (data) {
        this.request.send( JSON.stringify( data ) ); 
    },

    /**
     * Get error object
     * @returns 
     */
    getErrorObject () {
        return {
            "Ready State": this.request.readyState,
            "Response"   : this.request.responseText,
            "Status"     : this.request.status,
            "Status Text": this.request.statusText,
        };
    }

  }
  
export default xhr;
